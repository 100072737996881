@import "../../styles/variables";

.login-card,
.signup-card,
.payment-card,
.custom-card {
    .payment.card-text {
        margin-left: 0 !important;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #666666;
    }
}

.login-card,
.signup-card,
// .subscriptions-card,
.payment-card,
.custom-card {
    flex-direction: row;
    box-shadow: 0px 20px 32px rgba(0, 0, 0, 0.16);

    .card-body {
        padding: 2rem;
        overflow-y: auto;
        flex-direction: column;
        justify-content: center;

        .card-title {
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 16px;
            display: flex;
            align-items: center;
            color: $textColor;
        }

        a,
        p {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            text-decoration: none;
            color: #444444;
        }

        a {
            color: $textColor;
        }


    }

    .card-img-top {
        border-radius: 20px 0px 0px 20px;
    }
}