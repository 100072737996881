@import "../../styles/variables";

.ag-theme-alpine {
    --ag-header-foreground-color: #27963C;
    --ag-header-background-color: #fff;
}

.ag-theme-alpine .ag-header {
    font-size: large;
}

.ag-cell {
    border: none !important;
}