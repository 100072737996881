@import "../../styles/variables";


.orders-details-container {

    .page-title {
        color: $primaryColor;
    }

    .ag-row-last {
        border-top: 2px solid #444444;
        color: #444444 !important;
        text-decoration: none;
        font-size: 600;
        font-weight: 600;
    }

    .Sub.Total {
        color: #444444 !important;
        text-decoration: none;
        cursor: inherit !important;
    }
}

.payment-card-info,
.note-info-card,
.shipping-info-card {
    min-height: 280px;
}

.note-info-card {
    .card-body>.row>div {
        flex-direction: row-reverse;
        justify-content: space-between;
    }
}