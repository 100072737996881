.thumbnail-slider-wrap {
    margin-top: 15px;
    height: 85px;
}

.thumbnail-slider-wrap .slick-track .slick-slide {
    text-align: center;
}

.thumbnail-slider-wrap .slick-track .slick-slide img {
    width: 70%;
}

.slick-slide-image-main{
    align-items: center;
    margin: auto;
    width: 350px;
    height: 290px;
}

.slick-slide{
    margin-top: 10px;
}
