@import "../styles/variables";

.auth-container {
    height: 100vh;
    overflow: auto;
    background-image: url(../assets/images/auth_bg_img.svg);
    .btn {
        width: 100% !important;
    }
    .centered-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .login-card,
    .signup-card,
    .payment-card {
        .payment.card-text {
            margin-left: 0 !important;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            align-items: center;
            color: #666666;
        }
    }

    .login-card,
    .signup-card,
    .subscriptions-card,
    .payment-card {
        flex-direction: row;

        .card-body {
            padding: 3rem;
            overflow-y: auto;
            flex-direction: column;
            justify-content: center;

            .card-title {
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 16px;
                display: flex;
                align-items: center;
                color: $textColor;
            }

            a,
            p {
                font-style: normal;
                font-weight: 600;
                font-size: 10px;
                line-height: 16px;
                text-decoration: none;
                color: #444444;
            }

            a {
                color: $textColor;
            }


        }

        .card-img-top {
            border-radius: 20px 0px 0px 20px;
        }
    }

    .subscriptions-card {
        .subscriptions.card-title {
            padding-left: 0.75rem !important;
        }

        .subscriptions.card-text {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            align-items: center;
            color: #666666;
        }
    }
    
    .shop-url-to-install {
        .btn {
            padding: 0.5rem !important;
        }
    }
}

.auth-container.subscription {
    .card-body {
        padding-left: 1rem !important;
    }
}

.payment-save-card {
    label {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-decoration-line: underline;
        color: #202020;
        margin-left: 0.5rem;
    }
}

@media screen and (max-width: 600px) {

    .login-card,
    .signup-card,
    .subscriptions-card,
    .payment-card {
        width: 100vw;
        height: 100vh;

        .card-body {

            // padding: 2rem !important;
            .Subscription-card {
                .card-body {
                    padding: 2rem 2rem !important;
                }
            }
        }
    }


}

@media screen and (min-width: 992px) {

    .login-card,
    .signup-card,
    .subscriptions-card,
    .payment-card {
        width: 60vw !important;
        border-radius: 20px;
        background: #FFFFFF;
        box-shadow: 0px 20px 32px rgba(0, 0, 0, 0.16);
        border-radius: 20px;

        .card-body {
            padding-left: 4rem !important;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .card-img-top {
            width: 25vw;
            object-fit: cover;
            border-radius: 20px 0px 0px 20px;
        }
    }

    .subscriptions-card {
        width: 80vw !important;

        .card-body {
            padding: 1rem !important;

            .Subscription-card {
                .card-body {
                    padding: 1rem !important;
                }
            }
        }
    }

}