@import "../../styles/variables";

.Subscription-card {
    left: 0px;
    top: 0px;
    background: linear-gradient(154.64deg, $textColor 1.94%, $secondaryColor 84.73%);
    border-radius: 20px;
    height: 100%;

    .card-body {

        .card-title,
        .card-subtitle,
        .card-text {
            color: $whiteColor !important;
        }

        .card-title.price {
            position: relative;
            font-style: normal;
            font-weight: 700;
            font-size: 2.0rem;
            line-height: 18px;
            display: block;

            &::after {
                content: "per month";
                font-size: 10px;
                font-weight: 300;
                line-height: 10px;
                position: absolute;
                bottom: -1rem;
                left: 52%;
                transform: translateX(20%);
                white-space: nowrap;
                opacity: 0.9;
            }
        }

        .card-subtitle {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 24px;
        }

        .card-text {
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 12px;
            opacity: 0.8;

        }
    }

    .btn.btn-primary {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;
        height: 40px;
        left: 60px;
        top: 275px;
        background: $whiteColor;
        border-radius: 100px;
        color: $textColor;
        outline: none;
        border: none;

        span {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 14px;
        }
    }

    .most-popular {
        position: absolute;
        width: 120px;
        height: 32px;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        top: -16px;
        background: $whiteColor;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        color: #444444;
    }
}