@import "../../styles/variables";

.custom-container {
    padding: 1rem;
    overflow: auto;
    height: calc(100vh - 80px);
    .header-title {
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 18px;
        display: flex;
        align-items: center;
        color: $primaryColor;
    }
}

@media screen and (max-width: 576px) {
    .custom-container {
        padding: 1.5rem !important;
    }

}