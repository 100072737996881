@import "../../styles/variables";

.auth-container.admin-user-list-container {
    .centered-container {
        width: 85vw !important;

        .admin-user-list-container {
            width: 100% !important;

            .card-img-top {
                width: 30% !important;
            }

            .card-body {
                display: block;
                padding: 1rem !important;
            }

            .ag-header {
                background: $primaryColor;
            }

            .ag-header-cell {
                .ag-header-cell-text {
                    font-size: 1rem;
                    font-weight: 400;
                    color: $whiteColor;
                }
            }

            .ag-header-cell:last-child {
                .ag-header-cell-label {
                    justify-content: center;
                }
            }

            .btn-link {
                color: $primaryColor;
                text-decoration: none;
            }
        }
    }
}

@media screen and (max-width: $screen-sm) {
    .auth-container.admin-user-list-container {
        .centered-container {
            width: 100vw !important;
        }
    }
}