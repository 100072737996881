@import "../../../styles/variables";

.payment-pending-card,
.product-alert-card {
    overflow: hidden;
    min-height: 100%;
    .card-body {
        overflow: hidden;

        padding: 1rem;

        .card-title,
        .card-text,
        p {
            color: $whiteColor;
        }

        .btn {
            max-width: 10rem;
        }
    }

    .card-img-top {
        width: 35%;
        object-fit: contain;
        object-position: center;
        border-radius: 20px 0px 0px 20px;
    }

    overflow: hidden;
    background: linear-gradient(103.99deg, #27963C 0%, #156023 96.67%);
    border-radius: 10px;
}

.product-alert-card {
    flex-direction: row-reverse;
    .card-img-top {
        margin-right: -15%;
    }
}