.add-btn-row {
    position: sticky;
    top: -1rem;
    padding: 0.5rem;
    height: 3.25rem;
    z-index: 999;
    background-color: #fff;
    .btn {
        padding: 0.25rem 1.5rem !important;
    }
}