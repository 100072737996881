@import "../../styles/variables";

.modal-header {
    border-bottom: none;
    .modal-title {
        color: $primaryColor;
        font-weight: 600;
        font-size: 24px;
        line-height: 24px;
    }

    .btn-close {
        --bs-btn-close-bg: url(../../assets/icons/close.svg) !important;
        --bs-btn-close-opacity: 1 !important;
    }
}

.modal-body {
    .modal-title, .title {
        color: $primaryColor;
        font-weight: 600;
        font-size: 24px;
        line-height: 24px;
    }
    .sub-title {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
    }
    .btn.btn-primary,
.btn.btn-outline-primary {
    width: 100%;
    box-shadow: 0px 20px 24px rgba(39, 150, 60, 0.24);
    border-radius: 100px;
    outline: none;
    border: none;
    padding: 0.5rem 2rem;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: $whiteColor;
    white-space: nowrap;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

}